import React from 'react';
import { Router } from '@reach/router';

import AuthPopupCallback from '../../components/AuthPopupCallback';

const PopupCallback = () => (
  <Router>
    <AuthPopupCallback path="/popup-callback" />
  </Router>
);

export default PopupCallback;

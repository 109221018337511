import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Loading from 'react-loading';

import * as Auth from '../utils/auth';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function AuthPopupCallback({ location }) {
  useEffect(() => {
    const getAuth = async () => {
      await Auth.getAuthFromHash();
      const auth = Auth.getAuth();
      const userInfo = await Auth.getUserInfo();
      Auth.saveAuth({ ...auth, userInfo });
    };

    getAuth().catch(() => {
      navigate('/login');
    });
  }, [location.hash]);

  return (
    <Wrapper>
      <Loading type="bars" width={100} color="#4e4e4e" />
    </Wrapper>
  );
}

AuthPopupCallback.propTypes = {
  location: PropTypes.shape({}),
};

AuthPopupCallback.defaultProps = {
  location: undefined,
};

export default AuthPopupCallback;
